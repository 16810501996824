const SETTINGS = {
  BREAKPOINTS: {
    SM: 425,
    MD: 768,
    LG: 1024,
    XL: 1440,
    XXL: 1920
  },
  SCROLL_ANIMATION_BREAKPOINTS: {
    XS: 25,
    SM: 25,
    MD: 25,
    LG: 25
  },
};

export { SETTINGS };
