import Swiper from "swiper";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";

const initSocialNetworksSlider = () => {
  const socialNetworksSliders = document.querySelectorAll(
    ".social-networks-gallery .swiper"
  );
  for (const socialNetworksSlider of socialNetworksSliders) {
    new Swiper(socialNetworksSlider, {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      threshold: 5,
      navigation: {
        nextEl: ".navigation-slider-button--next",
        prevEl: ".navigation-slider-button--prev",
        disabledClass: "navigation-slider-button--disabled",
      },
      modules: [Navigation],
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 15,
          freeMode: false,
          allowTouchMove: false,
        },
        1920: {
          slidesPerView: 4,
          spaceBetween: 15,
        },
      },
    });
  }
};

initSocialNetworksSlider();
