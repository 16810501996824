import Swiper from "swiper";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";

const initNewsContainers = () => {
  const newsContainers = document.querySelectorAll(
    ".news-container.news-container--slider .swiper"
  );

  for (const newsContainer of newsContainers) {
    new Swiper(newsContainer, {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      modules: [Navigation],
    });
  }
};

initNewsContainers();
