class Tabs {
    tabsNode;
    tabsButtons;
    tabsSections;
    checkUrl;
    urlParam;

    constructor(tabs) {
        this.tabs = tabs;
        this.tabsButtons = this.tabs.querySelectorAll('nav > *[role="tab"]');
        this.tabsSections = this.tabs.querySelectorAll('.sections > *[role="tabpanel"]');
        this.checkUrl = Boolean(this.tabs.dataset.checkurl);
        this.urlParam = this.tabs.dataset.urlparam;

        if (this.checkUrl) {
            const url = new URL(`${window.location.protocol}//${window.location.host}/${window.location.pathname}${window.location.search}`)
            if (url.searchParams.has(this.urlParam)) {
                const matchStatus = url.searchParams.get(this.urlParam)
                this.activateTabButton(this.tabs.querySelector(`#tab-${matchStatus}`))
                this.activateTabSection(this.tabs.querySelector(`#panel-${matchStatus}`))
                setTimeout(() => {
                    window.scrollTo(0, this.tabs.offsetTop)
                }, 100)
            } else {
                this.activateFirstTab()
                url.searchParams.append(this.urlParam, this.tabsSections[0].id.split('panel-')[1])
                history.replaceState(null, null, "?" + url.searchParams.toString())
            }
        } else {
            this.activateFirstTab()
        }
        for (let tabButton of this.tabsButtons) {
            tabButton.addEventListener('click', this.toggleActiveClass.bind(this));
        }
    }

    activateFirstTab() {
        let target = this.tabsButtons[0].getAttribute('aria-controls')
        let tab = this.tabs.querySelector(`#${target}[role="tabpanel"]`)
        this.activateTabButton(this.tabsButtons[0])
        this.activateTabSection(tab)
    }

    activateTabButton(button) {
        button.setAttribute('aria-selected', true);
    }

    activateTabSection(tab) {
        tab.classList.add('tabs-panel--active');
    }

    toggleActiveClass(ev) {
        let target = ev.target.getAttribute('aria-controls');
        let tabSection = this.tabs.querySelector(`#${target}[role="tabpanel"]`);

        for (let tabButton of this.tabsButtons) {
            tabButton.setAttribute('aria-selected', false);
        };
        this.activateTabButton(ev.target)

        for (let tabSection of this.tabsSections) {
            tabSection.classList.remove('tabs-panel--active');
        }
        this.activateTabSection(tabSection)
        if (this.checkUrl) {
            const url = new URL(`${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}`)
            url.searchParams.set(this.urlParam, tabSection.id.split('panel-')[1])
            // Modify team-local parameter
            url.searchParams.set('team-local', tabSection.querySelectorAll('form select[name="team-local"]')[0].value)
            // Modify competition parameter
            url.searchParams.set('competition', tabSection.querySelectorAll('form select[name="competition"]')[0].value)
            // Modify url page part
            const paged = parseInt(tabSection.querySelectorAll('form input[name="paged"]')[0].value)
            const pathname = url.pathname.split('page')[0]
            history.replaceState(null, null, paged > 1 ? `${pathname}page/${paged}/?${url.searchParams.toString()}` : `${pathname}?${url.searchParams.toString()}`)
        }
    }

}

const initTabs = () => {
    let tabsNodes = document.getElementsByClassName('tabs');
    for (let tabs of tabsNodes) {
        new Tabs(tabs);
    }
}

initTabs();