import { getOffset, throttle } from "../../../inc/utils";

const NAV_ITEM_CURRENT_CLASSNAME = "team-main-players-terms-term--current";
const NAV_FIXED_CLASSNAME = "team-main-players-navigation--fixed";

class AnchorsNavigation {
  nav;
  navItems;
  contentCategories;
  navInitialTopPosition;
  status;
  contentCategoriesPosition;
  contentCategoriesPositionKeys;

  constructor(nav) {
    this.nav = nav;
    this.navInitialTopPosition = getOffset(this.nav).top;
    this.navItems = Array.from(this.nav.querySelectorAll("ul li"));
    this.contentCategories = Array.from(
      document.getElementsByClassName(this.nav.dataset.target)
    );

    this.contentCategoriesPosition = this.contentCategories.reduce(
      (accum, contentCategory) => {
        accum[contentCategory.id] =
          getOffset(contentCategory).top - this.nav.offsetHeight;
        return accum;
      },
      {}
    );
    this.contentCategoriesPositionKeys = Object.keys(
      this.contentCategoriesPosition
    );

    this.status = {
      prev: null,
      current: null,
      next: null,
    };

    this.bindEventListeners();
  }

  bindEventListeners() {
    window.addEventListener(
      "scroll",
      throttle(this.handleScrollActions.bind(this), 100)
    );
    window.addEventListener("load", this.setFirstStatus.bind(this));
    this.navItems.forEach((navItem) =>
      navItem.addEventListener("click", this.handleScrollActions.bind(this))
    );
  }

  setFirstStatus() {
    this.contentCategoriesPositionKeys.every((category, index) => {
      let currentCategoryPosition = this.contentCategoriesPosition[category];
      let nextCategoryPosition =
        this.contentCategoriesPosition[
          this.contentCategoriesPositionKeys[index + 1]
        ];
      if (
        window.scrollY > currentCategoryPosition &&
        (window.scrollY < nextCategoryPosition || !nextCategoryPosition)
      ) {
        this.status = {
          prev: this.contentCategoriesPositionKeys[index - 1] ?? null,
          current: category,
          next: this.contentCategoriesPositionKeys[index + 1] ?? null,
        };
        return false;
      } else {
        return true;
      }
    });
    if (this.status.current) {
      this.updateNav(this.status.current);
    }
    this.handleNavPosition();
  }

  handleScrollActions(ev) {
    this.handleNavPosition();
    if (
      this.status.prev &&
      window.scrollY <= this.contentCategoriesPosition[this.status.current]
    ) {
      this.updateNav(this.status.prev, this.status.current);
      this.updateStatus("up");
    } else if (
      this.status.next &&
      window.scrollY >= this.contentCategoriesPosition[this.status.next]
    ) {
      this.updateNav(this.status.next, this.status.current);
      this.updateStatus("down");
    } else if (
      !this.status.prev &&
      !this.status.current &&
      !this.status.next &&
      window.scrollY >=
        this.contentCategoriesPosition[this.contentCategoriesPositionKeys[0]]
    ) {
      this.updateNav(this.contentCategoriesPositionKeys[0]);
      this.updateStatus("current");
    } else if (
      !this.status.prev &&
      window.scrollY <
        this.contentCategoriesPosition[this.contentCategoriesPositionKeys[0]]
    ) {
      this.updateNav(null, this.contentCategoriesPositionKeys[0]);
      this.updateStatus("reset");
    }
  }

  updateStatus(direction) {
    if (direction === "up") {
      this.status.next = this.status.current;
      this.status.current = this.status.prev;
      let prevCategoryID = this.contentCategoriesPositionKeys.indexOf(
        this.status.current
      );
      this.status.prev =
        this.contentCategoriesPositionKeys[prevCategoryID - 1] ?? null;
    } else if (direction === "down") {
      this.status.prev = this.status.current;
      this.status.current = this.status.next;
      let nextCategoryID = this.contentCategoriesPositionKeys.indexOf(
        this.status.current
      );
      this.status.next =
        this.contentCategoriesPositionKeys[nextCategoryID + 1] ?? null;
    } else if (direction === "current") {
      this.status.current = this.contentCategoriesPositionKeys[0];
      this.status.next = this.contentCategoriesPositionKeys[1] ?? null;
    } else if (direction === "reset") {
      this.status = {
        prev: null,
        current: null,
        next: null,
      };
    }
  }

  updateNav(itemToSwichOn, itemToSwichOff) {
    if (itemToSwichOn) {
      let currentItemToSwichOn = this.nav.querySelector(
        `a[href='#${itemToSwichOn}']`
      ).parentNode;
      currentItemToSwichOn.classList.add(NAV_ITEM_CURRENT_CLASSNAME);
      this.nav.querySelector("ul").scroll(currentItemToSwichOn.offsetLeft, 0);
    }
    if (itemToSwichOff) {
      this.nav
        .querySelector(`a[href='#${itemToSwichOff}']`)
        .parentNode.classList.remove(NAV_ITEM_CURRENT_CLASSNAME);
    }
  }

  handleNavPosition() {
    if (window.scrollY >= this.navInitialTopPosition) {
      if (!this.nav.classList.contains(NAV_FIXED_CLASSNAME)) {
        this.nav.classList.add(NAV_FIXED_CLASSNAME);
      }
    } else {
      this.nav.classList.remove(NAV_FIXED_CLASSNAME);
    }
  }
}

export default AnchorsNavigation;

// Init
const anchorsNavigations =
  document.getElementsByClassName("anchors-navigation");

for (let anchorsNavigation of anchorsNavigations) {
  new AnchorsNavigation(anchorsNavigation);
}
