import { getOffset, throttle } from "../../../inc/utils";

const TICKETS_FLOATING_BUTTON_ACTIVE_CLASSNAME =
  "tickets-floating-button--active";

class TicketsFloatingButton {
  button;
  navButton;
  constructor(button) {
    this.button = button;
    this.navButtonMobile = document.querySelector(
      ".site-header-toolbar .extra-link--ticket"
    );
    this.navButtonDesktop = document.querySelector(
      ".extra-links-outer .button.button--ticket"
    );
    this.bindEventListeners();
  }

  bindEventListeners() {
    window.addEventListener(
      "scroll",
      throttle(this.handleButtonVisibility.bind(this), 100)
    );
  }

  handleButtonVisibility() {
    let viewportWidth = window.innerWidth;
    const navButtonMobileOffset =
      getOffset(this.navButtonMobile).top + this.navButtonMobile.offsetHeight;
    const navButtonDesktopOffset =
      getOffset(this.navButtonDesktop).top + this.navButtonDesktop.offsetHeight;
    let navButton = null;
    if (viewportWidth > 1024) {
      navButton = navButtonDesktopOffset;
    } else {
      navButton = navButtonMobileOffset;
    }
    if (window.scrollY > navButton) {
      this.button.classList.add(TICKETS_FLOATING_BUTTON_ACTIVE_CLASSNAME);
    } else {
      this.button.classList.remove(TICKETS_FLOATING_BUTTON_ACTIVE_CLASSNAME);
    }
  }
}

export default TicketsFloatingButton;

// Init
const ticketFloatingButton = document.getElementById("tickets-floating-button");
if (ticketFloatingButton) {
  new TicketsFloatingButton(ticketFloatingButton);
}
