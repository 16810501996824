import { throttle } from "../../../inc/utils";

class TextTravelling {
  el;
  direction;

  constructor(textElement) {
    this.el = textElement;
    this.direction = this.el.dataset.direction;

    document.addEventListener(
      "scroll",
      throttle(this.calcParallaxProgress, 100)
    );
  }

  calcParallaxProgress = () => {
    const position = this.el.getBoundingClientRect().top - window.innerHeight;
    let progress = 0;
    if (position < 0) {
      const positionRelative = Math.abs(position);
      const total = window.innerHeight + this.el.clientHeight;
      if (positionRelative < total) {
        progress = (positionRelative * 100) / total;
      }
      if (progress > 0) {
        this.el.style.transform = `translateX(${
          this.direction === "left" ? "-" : ""
        }${progress * 0.15}%)`;
      }
    }
  };
}

export default TextTravelling;

const textElements = document.querySelectorAll(`.text-travelling`);

for (let textElement of textElements) {
  new TextTravelling(textElement);
}
