document.body.classList.add("load");

import "./theme.scss";

// SETTINGS JS
import "./settings";

// IMPORT UTILS
import "../inc/utils";

// IMPORT ATOMS

// IMPORT COMPONENTS
import "./components/site-header/side-menu";
import "./components/megamenu/megamenu";
import "./components/news-container/news-container";
import "./components/team-content-main/anchors-navigation";
import "./components/slider-images/slider-images";
import "./components/section-matches/section-matches";
import "./components/tabs/tabs";
import "./components/social-networks-gallery/social-networks-gallery";
import "./components/tickets-floating-button/tickets-floating-button";
import "./components/filter-nav/filter-nav";
import "./components/pagination-nav/pagination-nav";
import "./components/animated-element/animated-element";
import "./components/animated-element/text-travelling";
