class PaginationNav {
    paginationNav
    paginationNavLinks
    loader
    constructor(paginationNav, loader) {
        this.paginationNav = paginationNav
        this.paginationNavLinks = this.paginationNav.querySelectorAll('a')
        this.loader = loader

        for (const paginationNavLink of this.paginationNavLinks) {
            paginationNavLink.addEventListener('click', this.enableLoader.bind(this))
        }

        window.addEventListener('unload', () => {
            document.body.classList.remove('modal-opened')
            this.loader.classList.remove('amore-loader--active')
        });
    }

    enableLoader = (ev) => {
        document.body.classList.add('modal-opened')
        this.loader.classList.add('amore-loader--active')
    }

}

export default PaginationNav

const initPaginationNav = () => {
    const paginationNavs = document.querySelectorAll('.pagination-nav')
    const loader = document.querySelector('#amore-loader')
    for (const paginationNav of paginationNavs) {
        new PaginationNav(paginationNav, loader)
    }
}

initPaginationNav();
