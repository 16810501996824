class FilterNav {
    filterNav
    filterNavForm
    filterNavFilters
    loader
    constructor(filterNav, loader) {
        this.filterNav = filterNav
        this.filterNavForm = this.filterNav.querySelector('form')
        this.filterNavFilters = this.filterNav.querySelectorAll('.filter-nav-filter')
        this.loader = loader

        for (const filterNavFilter of this.filterNavFilters) {
            const filter = filterNavFilter.querySelector('select, input[type="radio"]')
            if (filter.nodeName.toLowerCase() === 'select') {
                filter.addEventListener('change', this.applyFilter.bind(this))
            } else if (filter.nodeName.toLowerCase() === 'input') {
                if (filter.type === 'radio') {
                    filter.addEventListener('change', this.applyFilter.bind(this))
                }
            }
        }

        window.addEventListener('unload', () => {
            document.body.classList.remove('modal-opened')
            this.loader.classList.remove('amore-loader--active')
        });
    }

    applyFilter = (ev) => {
        document.body.classList.add('modal-opened')
        this.loader.classList.add('amore-loader--active')
        this.filterNavForm.submit()
    }

}

export default FilterNav

const initFilterNav = () => {
    const filterNavs = document.querySelectorAll('.filter-nav')
    const loader = document.querySelector('#amore-loader')
    for (const filterNav of filterNavs) {
        new FilterNav(filterNav, loader)
    }
}

initFilterNav();