import Swiper from "swiper";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";

const initMatchesSlider = () => {
  const matchesHomeSliders = document.querySelectorAll(
    ".section-matches.section-matches--slider-home .swiper"
  );

  for (const matchesHomeSlider of matchesHomeSliders) {
    new Swiper(matchesHomeSlider, {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      threshold: 5,
      navigation: {
        nextEl: ".navigation-slider-button--next",
        prevEl: ".navigation-slider-button--prev",
        disabledClass: "navigation-slider-button--disabled",
      },
      modules: [Navigation],
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 60,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
          freeMode: false,
          allowTouchMove: false,
        },
        1440: {
          slidesPerView: 3,
          spaceBetween: 60,
          freeMode: false,
          allowTouchMove: false,
        },
      },
    });
  }

  const matchesCalendarSliders = document.querySelectorAll(
    ".section-matches.section-matches--slider-calendar .swiper"
  );
  for (const matchesCalendarSlider of matchesCalendarSliders) {
    new Swiper(matchesCalendarSlider, {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      threshold: 5,
      navigation: {
        nextEl: ".navigation-slider-button--next",
        prevEl: ".navigation-slider-button--prev",
        disabledClass: "navigation-slider-button--disabled",
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 60,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
          freeMode: false,
          allowTouchMove: false,
        },
        1920: {
          slidesPerView: 4,
          spaceBetween: 120,
        },
      },
    });
  }
};

initMatchesSlider();
