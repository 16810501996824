class Megamenu {
  el;
  items;
  backdrop;
  navbar;
  currentSection;

  constructor(el) {
    this.el = el;
    this.items = this.el.querySelectorAll('.megamenu-navbar button');
    this.navbar = this.el.querySelector('.megamenu-navbar');
    this.backdrop = this.el.querySelector('.megamenu-backdrop');
    this.currentSection = '';
    this.bindListeners();
  }

  bindListeners() {
    for(let item of this.items) {
      item.addEventListener('click', this.activateItem.bind(this))
    }
    this.backdrop.addEventListener('click', this.close.bind(this))
    this.navbar.addEventListener('click', this.checkCloseOverNavbar.bind(this));
  }

  activateItem(ev) {
    const target = ev.currentTarget.getAttribute('data-target');
    const targetEl = document.getElementById(target);
    if(targetEl && !targetEl.classList.contains('active')) {
      this.el.classList.add('open');
      this.currentSection = target;
      this.deactivateActive();
      targetEl.classList.add('active');
    }
    else {
      this.close();
    }
  }

  deactivateActive() {
    const activeSections = this.el.querySelectorAll('.megamenu-section.active');
    for(let section of activeSections) {
      section.classList.remove('active');
    }
  }

  close() {
    this.deactivateActive();
    this.el.classList.remove('open');
    this.currentSection = '';
  }

  checkCloseOverNavbar(ev) {
    if(ev.target.tagName !== 'BUTTON') {
      this.close();
    }
  }

}

const megamenu = document.getElementById('megamenu');
if (megamenu) {
  new Megamenu(megamenu);
}