import Swiper from "swiper";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";

const initImageGallery = () => {
  const imageGalleries = document.querySelectorAll(".slider-images .swiper");

  for (const imageGallery of imageGalleries) {
    const withSlideOffset = Boolean(imageGallery.dataset.slideoffset);

    const swiper = new Swiper(imageGallery, {
      slidesPerView: "auto",
      spaceBetween: 20,
      slidesOffsetBefore: 0,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      breakpoints: {
        768: {
          spaceBetween: 15,
          slidesOffsetBefore: withSlideOffset ? 40 : 0,
        },
        1440: {
          slidesOffsetBefore: withSlideOffset ? 60 : 0,
        },
        1920: {
          slidesOffsetBefore: withSlideOffset ? 80 : 0,
        },
      },
      navigation: {
        disabledClass: "navigation-slider-button--disabled",
        nextEl: ".navigation-slider-button--next",
        prevEl: ".navigation-slider-button--prev",
      },
      modules: [Navigation],
    });
  }
};
initImageGallery();
