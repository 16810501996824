import { getOffset, throttle } from "../../../inc/utils";
import { SETTINGS } from "../../settings";

const CLASSES = {
  ANIMATION_TO_FIRE_CLASS: "animation",
  ANIMATION_FIRED_CLASS: "animation--fired",
  ANIMATION_ON_SCROLL_CLASS: "animation--onscroll",
  ANIMATION_ON_LOAD_CLASS: "animation--onload",
  ANIMATION_VISIBLE_GRID: "animation--visible-grid",
  ANIMATION_ON_HOVER: "animation--onhover",
  ANIMATION_ON_CHILDREN: "animation--onchildren",
};

const INITIAL_VISIBLE_GRID_ITEMS = {
  MOBILE: [0, 1],
  DESKTOP: [0, 1, 2, 3],
};

class AnimatedElement {
  animatedElement;
  animationType;

  constructor(animatedElement) {
    this.animatedElement = animatedElement;

    if (
      this.animatedElement.classList.contains(CLASSES.ANIMATION_VISIBLE_GRID)
    ) {
      if (window.innerWidth > SETTINGS.BREAKPOINTS.SM) {
        if (
          INITIAL_VISIBLE_GRID_ITEMS.DESKTOP.includes(
            parseInt(this.animatedElement.dataset.index)
          )
        ) {
          this.animate();
        }
      } else {
        if (
          INITIAL_VISIBLE_GRID_ITEMS.MOBILE.includes(
            parseInt(this.animatedElement.dataset.index)
          )
        ) {
          this.animate();
        }
      }
    }

    /** First check to animate element if is visible */
    this.managePageScrolling();

    this.bindListeners();
  }

  bindListeners() {
    if (
      this.animatedElement.classList.contains(CLASSES.ANIMATION_ON_LOAD_CLASS)
    ) {
      window.addEventListener("load", () => {
        this.animate();
      });
    } else if (
      this.animatedElement.classList.contains(CLASSES.ANIMATION_ON_HOVER)
    ) {
      window.addEventListener("mouseover", this.animate());
    } else {
      window.addEventListener(
        "scroll",
        throttle(this.managePageScrolling.bind(this), 100)
      );
    }
  }

  managePageScrolling() {
    if (
      this.isAnimationAplicable(
        window.pageYOffset || document.documentElement.scrollTop
      )
    ) {
      this.animate();
    }
  }

  animate() {
    this.animatedElement.classList.add(CLASSES.ANIMATION_FIRED_CLASS);
  }

  isAnimationAplicable(scrollPosition) {
    if (
      this.animatedElement.classList.contains(CLASSES.ANIMATION_FIRED_CLASS)
    ) {
      return false;
    }
    let currentDeviceWidth =
      window.innerWidth < SETTINGS.BREAKPOINTS.SM
        ? "LG"
        : window.innerWidth >= SETTINGS.BREAKPOINTS.SM &&
          window.innerWidth < SETTINGS.BREAKPOINTS.MD
        ? "SM"
        : window.innerWidth >= SETTINGS.BREAKPOINTS.MD &&
          window.innerWidth < SETTINGS.BREAKPOINTS.LG
        ? "MD"
        : "LG";
    let breakpoint =
      scrollPosition +
      (window.innerHeight -
        (window.innerHeight *
          SETTINGS.SCROLL_ANIMATION_BREAKPOINTS[currentDeviceWidth]) /
          100);

    return breakpoint > getOffset(this.animatedElement).top;
  }
}

export default AnimatedElement;

const animatedElements = document.querySelectorAll(
  `.${CLASSES.ANIMATION_TO_FIRE_CLASS}:not(.${CLASSES.ANIMATION_ON_CHILDREN})`
);

for (let animatedElement of animatedElements) {
  new AnimatedElement(animatedElement);
}
