
class SideMenu {
  el;
  buttonOpen;
  buttonClose;

  constructor(sideMenu) {
    this.el = sideMenu;
    this.buttonOpen = document.querySelector('.button-side-menu');
    this.buttonClose = this.el.querySelector('.button-close');

    this.bindListeners();
  }

  bindListeners() {
    this.buttonOpen.addEventListener('click', this.open.bind(this));
    this.buttonClose.addEventListener('click', this.close.bind(this));
  }

  open() {
    this.el.classList.add('open');
    document.body.classList.add('modal-opened')
  }

  close() {
    this.el.classList.remove('open');
    document.body.classList.remove('modal-opened')
  }
}

const sideMenu = document.getElementById('side-menu');
if (sideMenu) {
  new SideMenu(sideMenu);
}

const linkWithSubmenu = document.querySelector('.side-menu .menu-item-has-children > a');

if (linkWithSubmenu) {
  linkWithSubmenu.onclick = function (e) {
    e.preventDefault();

    const submenu = document.querySelector('.side-menu .menu-item-has-children > .sub-menu');

    if (submenu) {
      submenu.classList.toggle("submenu-show");
    }
    clubLink.classList.toggle("open");
  };
}
